<template>
	<div id="pwd-reset">
		<Transition name="fade">
			<div class="alert" v-if="showAlert" :class="{'success': alertType === 'success', 'error': alertType === 'error'}">
				{{alertText}}
			</div>
		</Transition>
		
		<div class="title">Watchtwoord wijzigen</div>
		<div class="input-wrapper">
			<label>
				Nieuw wachtwoord
				<input type="text" v-model="pwd">
			</label>
			<div class="pwd-instructions">
				<div class="pwd-instructions-title">
					Wachtwoordvereisten
				</div>
				<ul class="pwd-instructions-list">
					<li class="pwd-instructions-item">moet minimaal uit 5 tekens bestaan</li>
					<li class="pwd-instructions-item">moet minder dan 128 tekens zijn</li>
					<li class="pwd-instructions-item">moet anders zijn dan het e-mailadres</li>
				</ul>
			</div>
		</div>
		<button type="button" class="submit-btn" @click.prevent="submitPwd" :class="{'disabled': !pwd.length || showAlert}">
			Wachtwoord wijzigen
		</button>
	</div>
</template>

<script>

import axios from 'axios';

export default {
	name: 'pwdSetup',
	data() {
		return {
			pwd: '',
			token: '',
			showAlert: false,
			alertType: '',
			alertText: 'Password was changed successfully'
		}
	},
	mounted() {
		this.token = Object.keys(this.$route.query)[0];
		console.log(this.token);
	},
	components: {},
	methods: {
		submitPwd() {
			if (this.pwd.length >= 5 && this.pwd.length <= 128) {
				console.log(this.pwd);
				this.showAlert = true;
				axios.post('https://appt-accessibility-api-demo.crio-server.com/api/password/reset', {}, {
					params: {
						token: this.token,
						password: this.pwd
					}
				}).then((res) => {
					this.alertType = 'success';
					this.alertText = 'Password was changed successfully';
					setTimeout(() => {
						this.showAlert = false;
					}, 1500)
				}).catch((err) => {
					console.log(err)
					this.alertType = 'error';
					this.alertText = 'Something went wrong';
					setTimeout(() => {
						this.showAlert = false;
					}, 1500)
				})
			}
		}
	}
}
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
#pwd-reset {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 50px;
	width: 320px;
	.alert {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		box-sizing: border-box;
		padding: 15px;
		color: white;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		font-weight: bold;
		font-size: 14px;
		&.success {
			background-color: green;
		}
		&.error {
			background-color: red;
		}
	}
	.title {
		width: 100%;
		text-align: center;
		font-size: 18px;
		margin-bottom: 20px;
		color: #4d4d4d;
	}
	.input-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		label {
			width: 100%;
			text-align: left;
			margin-bottom: 15px;
			font-weight: bold;
			color: #4b4e55;
			font-size: 14px;
			input {
				margin-top: 5px;
				width: 100%;
				outline: none;
				border: 1px solid darkgray;
				height: 40px;
				border-radius: 4px;
				padding: 5px 10px;
				box-sizing: border-box;
			}
		}
		.pwd-instructions {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: 20px;
			.pwd-instructions-title {
				text-align: left;
				width: 100%;
				color: #a9a9a9;
				font-size: 14px;
				margin-bottom: 10px;
			}
			.pwd-instructions-list {
				padding: 0 0 0 15px;
				margin: 0;
				list-style-type: square;
				.pwd-instructions-item {
					color: #a9a9a9;
					font-size: 14px;
					text-align: left;
				}
			}
		}
	}
	
	.submit-btn {
		margin: 0 auto;
		width: 100%;
		background-color: #1f72b6;
		color: #fff;
		font-weight: bold;
		text-align: center;
		box-sizing: border-box;
		padding: 15px;
		border-radius: 4px;
		cursor: pointer;
		border: none;
		&.disabled {
			pointer-events: none;
		}
	}
}
</style>
